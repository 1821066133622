import React from "react";
import classNames from "classnames";

const Heading = ({ text, size, className }) => {
  const headingClass = classNames(
    "font-[600]",
    {
      "text-[20px] md:text-[20px] font-bold uppercase": !size,
      "text-[30px] md:text-[40px]": size === "large",
      "text-[20px] md:text-[30px]": size === "small",
    },
    className
  );

  return <h2 className={headingClass}>{text}</h2>;
};

export default Heading;
