import React, { useRef, useState } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import Slider from "react-slick";
import { formatAmountToINR, getAssetUri } from "../../utils/helper";
import { FaTruck, FaShieldAlt, FaShoppingCart } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { FaCartPlus } from "react-icons/fa";
import { addToCart } from "../../redux/headerSlice";
import {
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
const ProductDetail = ({ productDetail }) => {
  const sliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);
  const [mainSliderIndex, setMainSliderIndex] = useState(0);
  const dispatch = useDispatch();
  const images =
    productDetail && undefined !== productDetail.images
      ? productDetail.images.map((image) => image.image)
      : [];
  const mainSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index) => setMainSliderIndex(index),
  };
  const thumbnailSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
  };

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        product_id: productDetail.product.id,
        variation_id: productDetail.id,
        quantity: 1,
      })
    );
  };
  const handleVariationAddToCart = (id) => {
    dispatch(
      addToCart({
        product_id: productDetail.product.id,
        variation_id: id,
        quantity: 1,
      })
    );
  };

  const handleThumbnailClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };
  return (
    <div>
      <div className="container max-w-4xl mx-auto lg:max-w-7xl">
        <div className="grid gap-2 px-5 py-5 md:grid-cols-2 md:px-0">
          <div className="grid grid-cols-1">
            <div className="p-0 m-0 border-slate-400 productDetail productZoom">
              <Slider ref={sliderRef} {...mainSliderSettings}>
                {images.map((image, index) => (
                  <div key={index} className="item">
                    <InnerImageZoom
                      zoomType="hover"
                      zoomScale={1}
                      src={getAssetUri("variations", image)}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <Slider
              className="flex justify-center zoomSlider"
              ref={thumbnailSliderRef}
              {...thumbnailSliderSettings}
            >
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`item ${
                    index === mainSliderIndex ? "active" : ""
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img
                    style={{ height: "100px", width: "100px" }}
                    className="w-100"
                    src={getAssetUri("variations", image)}
                    alt={`Thumbnail ${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="w-full md:col-span-1">
            <div className="p-8 bg-white rounded-lg product-container">
              <h2 className="mb-4 font-semibold text-gray-800 product-title text-heading">
                {productDetail.title}
              </h2>
              <Rating
                size="small"
                name="read-only"
                value={productDetail.rating}
                className="text-left"
                readOnly
              />
              <div className="flex items-center justify-start gap-4 price-section">
                <p className="current-price text-3xl font-bold text-[#333]">
                  {formatAmountToINR(productDetail.active_price.price)}
                </p>
                <p className="text-lg text-gray-500 line-through previous-price">
                  {formatAmountToINR(productDetail.active_price.mrp)}
                  <span className="text-sm text-gray-400">Tax excluded</span>
                </p>
              </div>
              <div className="grid grid-cols-1 gap-6 mt-3 md:grid-cols-2">
                {(productDetail?.product?.variations || []).filter((item) => item.id !== productDetail.id).map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        className="relative p-4 transition-shadow bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md"
                      >
                        <div className="flex items-center justify-between mb-2">
                          <h3 className="text-sm font-medium text-gray-800 line-clamp-1">
                            {item.title}
                          </h3>
                          <div
                            className="p-2 ml-2 text-white transition-colors bg-red-600 rounded-full shadow-lg cursor-pointer hover:bg-red-700"
                            onClick={() => handleVariationAddToCart(item.id)}
                          >
                            <FaShoppingCart className="text-sm" />
                          </div>
                        </div>
                        <table className="w-full text-sm text-left text-gray-600">
                          <tbody>
                            {(item?.values || []).map((attr, index) => (
                              <tr key={index} className="border-t">
                                <th className="py-1 pr-2 text-xs font-medium text-gray-700">
                                  {attr.value.attribute.attribute_name}
                                </th>
                                <td className="py-1 text-xs">
                                  {attr.value.attribute_value}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    );
                  }
                )}
              </div>
              <p className="my-6 text-base text-gray-700 description">
                {productDetail.product.description}
              </p>
              <div className="mb-2 feature-list">
                {productDetail.product &&
                  productDetail.product.features &&
                  productDetail.product.features.map((item, index) => {
                    return (
                      <li key={index} className="text-base">
                        {item.feature}
                      </li>
                    );
                  })}
              </div>
              {productDetail.product.deliver_between > 0 && (
                <div className="flex items-center mb-2 text-base text-gray-800 delivery-info">
                  <FaTruck className="mr-2 text-green-500" />
                  <p>
                    {`Delivery within ${productDetail.product.deliver_between} business days`}
                  </p>
                </div>
              )}
              {productDetail.product.days_of_return > 0 && (
                <div className="flex items-center mb-5 text-base text-gray-800 return-policy">
                  <FaShieldAlt className="mr-2 text-red-500" />
                  <p>
                    {`${productDetail.product.days_of_return} -day return
                          policy: We offer a ${productDetail.product.days_of_return} -day return policy for this product.`}
                  </p>
                </div>
              )}

              {productDetail.active_price.offer &&
                productDetail.active_price.offer == "50" && (
                  <div className="p-4 my-5 text-green-700 bg-green-100 border-l-4 border-green-500">
                    <p className="font-semibold">
                      Special Offer: Buy One, Get One Free!
                    </p>
                    <p>
                      For a limited time, buy one and get another one for free.
                      Hurry up!
                    </p>
                  </div>
                )}
              <TableContainer className="my-3" component={Paper}>
                <Table>
                  <TableBody>
                    {productDetail.values.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell className="font-semibold">
                          {item.value.attribute.attribute_name}
                        </TableCell>
                        <TableCell>{item.value.attribute_value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <button
                type="button"
                onClick={handleAddToCart}
                className="flex items-center justify-center w-full py-2 mt-4 mb-2 text-sm font-medium text-white rounded-lg shadow-lg md:mt-0 bg-gradient-to-r bg-primary-500 hover:bg-accent-500 me-2 "
              >
                <FaCartPlus className="mr-2" />
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
