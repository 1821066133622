import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";

const ReturnPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Return Policy - Variety Mega Store</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              Return Policy
            </strong>
          </h1>
        </div>
      </section>
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5 bg-white mt-5">
        <section>
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-1 lg:gap-16">
            <div className="lg:py-24">
              <h2 className="text-3xl font-bold sm:text-4xl">
                Return Policy
              </h2>
              <p className="mt-4 mb-6 text-lg text-gray-700">
                We offer a refund or exchange within the first 7 days from the
                date of your purchase. If 7 days have passed since your purchase,
                you will not be offered a return, exchange, or refund of any kind.
              </p>
              <p className="mb-4 text-lg text-gray-700">
                In order to become eligible for a return or an exchange:
              </p>
              <ul className="pl-5 mb-6 text-lg text-gray-700 list-disc">
                <li>The purchased item should be unused and in the same condition as you received it.</li>
                <li>The item must have its original packaging.</li>
                <li>If the item was purchased on sale, it may not be eligible for a return or exchange.</li>
              </ul>
              <p className="mb-4 text-lg text-gray-700">
                Only items found defective or damaged are eligible for an exchange based on your request.
              </p>

              <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800">
                Exempted Categories
              </h2>
              <p className="mb-6 text-lg text-gray-700">
                You agree that certain categories of products/items may be exempted from returns or refunds. Such categories will be identified to you at the time of purchase.
              </p>

              <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800">
                Return and Exchange Process
              </h2>
              <p className="mb-4 text-lg text-gray-700">
                For accepted return/exchange requests, once we receive and inspect the returned product, we will notify you via email about the receipt of the returned/exchanged product. If the product passes our quality check, your return or exchange request will be processed according to our policies.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ReturnPolicy;
