import React from "react";

export const CategoryFilter = ({ carCategories, selectedItem, onClick }) => {
  return (
    <div className="flex gap-5 text-[12px] font-[550]">
      {carCategories.map((item, index) => (
        <button
          key={index}
          onClick={() => onClick(index)}
          className={`pb-3 ${
            selectedItem === index ? "text-red-500" : "text-black"
          } relative cursor-pointer`}
        >
          <p>{item.name}</p>
          {selectedItem === index && (
            <div className="absolute border-2 border-b w-full border-red-500 bottom-[-3px] left-0"></div>
          )}
        </button>
      ))}
    </div>
  );
};
