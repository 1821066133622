import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Register from "../pages/Register";
import About from "../pages/About";
import Contact from "../pages/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ReturnPolicy from "../pages/ReturnPolicy";
import RefundPolicy from "../pages/RefundPolicy";
import ShippingPolicy from "../pages/ShippingPolicy";
import TermCondition from "../pages/TermCondition";
import ProductDetail from "../pages/ProductDetail";
import ForgetPassword from "../pages/ForgetPassword";
import Shop from "../pages/Shop";
import MyAccount from "../pages/MyAccount";
import WishList from "../pages/WishList";
import Checkout from "../pages/Checkout";
import Login from "../pages/Login";
import Search from "../pages/Search";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/return-policy" element={<ReturnPolicy />} />
      <Route path="/shipping-policy" element={<ShippingPolicy />} />
      <Route path="/terms-and-condition" element={<TermCondition />} />
      <Route path="/product-detail/:id/:title" element={<ProductDetail />} />
      <Route path="/shop/:id/:name" element={<Shop />} />

      <Route path="/login" element={<Login />} />
      <Route path="/wishlist" element={<WishList />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/my-account" element={<MyAccount />} />
      <Route path="/search/:title" element={<Search />} />
    </Routes>
  );
}
