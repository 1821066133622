import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy - Variety Mega Store</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              Privacy Policy
            </strong>
          </h1>
        </div>
      </section>
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5 bg-white mt-5">
        <section>
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-1 lg:gap-16">
            <div className="lg:py-24">
              <h2 className="text-3xl font-bold sm:text-4xl">Privacy Policy</h2>
              <p className="mt-4 mb-6 text-lg text-gray-700">
                This document includes your business details and context of details we can pre-fill which you can edit and use for reference.
              </p>
              <h3 className="mt-6 mb-4 text-xl font-semibold text-gray-800">Introduction</h3>
              <p className="mb-4 text-lg text-gray-700">
                This Privacy Policy describes how VARIETY BOOK CENTRE AND STATIONERS and its affiliates (collectively "VARIETY BOOK CENTRE AND STATIONERS, we, our, us") collect, use, share, protect or otherwise process your information/ personal data through our website https://varietymegastore.com (hereinafter referred to as Platform).
              </p>
              <p className="mb-4 text-lg text-gray-700">
                Please note that you may be able to browse certain sections of the Platform without registering with us. We do not offer any product/service under this Platform outside India and your personal data will primarily be stored and processed in India. By visiting this Platform, providing your information or availing any product/service offered on the Platform, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the Terms of Use and the applicable service/product terms and conditions, and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If you do not agree please do not use or access our Platform.
              </p>

              <h3 className="mt-6 mb-4 text-xl font-semibold text-gray-800">Collection</h3>
              <p className="mb-4 text-lg text-gray-700">
                We collect your personal data when you use our Platform, services or otherwise interact with us during the course of our relationship. Some of the information that we may collect includes but is not limited to personal data/information provided to us during sign-up/registering or using our Platform such as name, date of birth, address, telephone/mobile number, email ID and/or any such information shared as proof of identity or address.
              </p>
              <p className="mb-4 text-lg text-gray-700">
                Some of the sensitive personal data may be collected with your consent, such as your bank account or credit or debit card or other payment instrument information or biometric information such as your facial features or physiological information (in order to enable use of certain features when opted for, available on the Platform) etc. All of the above being in accordance with applicable law(s). You always have the option to not provide information, by choosing not to use a particular service or feature on the Platform.
              </p>

              <h3 className="mt-6 mb-4 text-xl font-semibold text-gray-800">Usage</h3>
              <p className="mb-4 text-lg text-gray-700">
                We use personal data to provide the services you request. To the extent we use your personal data to market to you, we will provide you the ability to opt-out of such uses. We use your personal data to assist sellers and business partners in handling and fulfilling orders; enhancing customer experience; resolving disputes; troubleshooting problems; informing you about online and offline offers, products, services, and updates; customizing your experience; detecting and protecting us against error, fraud, and other criminal activity; enforcing our terms and conditions; conducting marketing research, analysis, and surveys; and as otherwise described to you at the time of collection of information.
              </p>

              <h3 className="mt-6 mb-4 text-xl font-semibold text-gray-800">Sharing</h3>
              <p className="mb-4 text-lg text-gray-700">
                We may share your personal data internally within our group entities, our other corporate entities, and affiliates to provide you access to the services and products offered by them. These entities and affiliates may market to you as a result of such sharing unless you explicitly opt-out.
              </p>
              <p className="mb-4 text-lg text-gray-700">
                We may disclose personal data to third parties such as sellers, business partners, third-party service providers including logistics partners, prepaid payment instrument issuers, third-party reward programs, and other payment opted by you. These disclosures may be required for us to provide you access to our services and products offered to you, to comply with our legal obligations, to enforce our user agreement, to facilitate our marketing and advertising activities, to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our services.
              </p>

              <h3 className="mt-6 mb-4 text-xl font-semibold text-gray-800">Security Precautions</h3>
              <p className="mb-4 text-lg text-gray-700">
                To protect your personal data from unauthorized access or disclosure, loss, or misuse, we adopt reasonable security practices and procedures. Once your information is in our possession or whenever you access your account information, we adhere to our security guidelines to protect it against unauthorized access and offer the use of a secure server.
              </p>

              <h3 className="mt-6 mb-4 text-xl font-semibold text-gray-800">Data Deletion and Retention</h3>
              <p className="mb-4 text-lg text-gray-700">
                You have an option to delete your account by visiting your profile and settings on our Platform, this action would result in you losing all information related to your account. You may also write to us at the contact information provided below to assist you with these requests.
              </p>

              <h3 className="mt-6 mb-4 text-xl font-semibold text-gray-800">Your Rights</h3>
              <p className="mb-4 text-lg text-gray-700">
                You may access, rectify, and update your personal data directly through the functionalities provided on the Platform.
              </p>

              <h3 className="mt-6 mb-4 text-xl font-semibold text-gray-800">Consent</h3>
              <p className="mb-4 text-lg text-gray-700">
                By visiting our Platform or by providing your information, you consent to the collection, use, storage, disclosure, and otherwise processing of your information on the Platform in accordance with this Privacy Policy.
              </p>

              <h3 className="mt-6 mb-4 text-xl font-semibold text-gray-800">Changes to this Privacy Policy</h3>
              <p className="mb-4 text-lg text-gray-700">
                Please check our Privacy Policy periodically for changes. We may update this Privacy Policy to reflect changes to our information practices. We may alert/notify you about the significant changes to the Privacy Policy, in the manner as may be required under applicable laws.
              </p>

              <h3 className="mt-6 mb-4 text-xl font-semibold text-gray-800">Grievance Officer</h3>
              <p className="mb-4 text-lg text-gray-700">
                <strong>Insert Name of the Office:</strong> Variety Mega Store
              </p>
              <p className="mb-4 text-lg text-gray-700">
                <strong>Designation:</strong> Marketing Manager
              </p>
              <p className="mb-4 text-lg text-gray-700">
                <strong>Insert Name and Address of the Company:</strong> Shop No.18, Vishwakarma Paradise, Phase-1, Ambadi Rd, near Camel Classes, Sai Nagar, Vasai West, Vasai-Virar, Maharashtra - 401202
              </p>
              <p className="mb-4 text-lg text-gray-700">
                <strong>Contact Us:</strong> +91-7558507673 | megastorevariety@gmail.com
              </p>
              <p className="mb-4 text-lg text-gray-700">
                <strong>Phone:</strong> Monday - Friday (9:00 - 18:00)
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
