import { Link } from "react-router-dom";
import React from "react";
import { getAssetUriWithSize } from "../../utils/helper";
const NavLinks = ({ links }) => {
  return (
    <>
      {links.map((item, index) =>
        !item.icon ? (
          <li key={index}>
            <Link
              to={item.link}
              className="flex items-center justify-between py-1 text-sm hover:text-primary"
            >
              <p className="font-bold">{item.name}</p>
            </Link>
          </li>
        ) : (
          <li key={index} className="relative group">
            <div className="flex items-center justify-between py-1 text-sm cursor-pointer hover:text-primary">
              <p className="mr-2 font-bold">{item.name}</p>
              {item.icon}
            </div>
            <div className="absolute left-0 z-10 py-5">
              <ul className="bg-white py-3 searchShadow w-[250px] hidden group-hover:grid grid-cols-1">
                {item.submenu.map((submenu, index) => (
                  <li
                    key={index}
                    className="relative px-3 hover:bg-primary-500 hover:text-white group/products"
                  >
                    <Link
                      to={submenu.link}
                      className="flex items-center justify-between py-1 text-sm"
                    >
                      <div className="flex items-center">
                        <img
                          alt="icon"
                          className="rounded-[4px]"
                          height={30}
                          width={30}
                          src={getAssetUriWithSize(
                            "mainCategory",
                            submenu.image,
                            30,
                            30
                          )}
                        />
                        <p className="mx-3">{submenu.name}</p>
                      </div>
                      {submenu.icon}
                    </Link>
                    {submenu.children && (
                      <ul className="absolute top-0 hidden w-auto py-3 bg-white left-full primaryShadow text-nowrap group-hover/products:block">
                        {submenu.children.map((item, index) =>
                          item.link !== null && item.name !== null ? (
                            <li
                              key={index}
                              className="relative px-3 text-black hover:bg-primary-500 hover:text-white group/products"
                            >
                              <Link
                                to={item.link}
                                className="flex items-center justify-between py-1 text-sm"
                              >
                                <div className="flex items-center">
                                  <img
                                    alt="icon"
                                    className="rounded-[4px]"
                                    height={30}
                                    width={30}
                                    src={getAssetUriWithSize(
                                      "mainCategory",
                                      item.image,
                                      30,
                                      30
                                    )}
                                  />
                                  <p className="ml-3 mr-7">{item.name}</p>
                                </div>
                              </Link>
                            </li>
                          ) : (
                            <></>
                          )
                        )}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </li>
        )
      )}
    </>
  );
};

export default NavLinks;
export const MobileNav = ({ links }) => {
  return (
    <ul className="h-full mt-6 space-y-1">
      {links.map((item, index) => {
        return !item.icon ? (
          <Link
            key={index}
            to={item.link}
            className="block px-4 py-2 text-sm font-medium text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
          >
            {item.name}
          </Link>
        ) : (
          <li key={index}>
            <details>
              <summary className="flex items-center justify-between px-4 py-2 text-gray-500 rounded-lg cursor-pointer hover:bg-primary-100 hover:text-gray-700">
                <span className="text-sm font-medium">{item.name}</span>
                <span className="transition duration-300 shrink-0 group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </summary>
              <ul className="h-full mt-2 space-y-1">
                {item.submenu.map((submenu, index) => {
                  return (
                    <li key={index} className="group/products">
                      <Link
                        to={submenu.link}
                        className="flex items-center justify-between px-4 py-2 text-xs font-semibold text-gray-500 rounded-lg  hover:bg-primary-100 hover:text-gray-700"
                      >
                        <div className="flex items-center">
                          <img
                            alt="icon"
                            className="rounded-[4px] mr-3"
                            height={30}
                            width={30}
                            src={getAssetUriWithSize(
                              "mainCategory",
                              submenu.image,
                              30,
                              30
                            )}
                          />
                          <p className="text-wrap">{submenu.name}</p>
                        </div>
                        <p className="ml-3">{submenu.icon}</p>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </details>
          </li>
        );
      })}
    </ul>
  );
};
