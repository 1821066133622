import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import Input from "../utils/Input";
import { ForgetPasswordSchema, ResetPasswordSchema } from "../../validation/authFormSchema";
import apiRequest from "../../utils/apiRequest";
import { Link, useNavigate } from "react-router-dom";
import { HOME_ROUTE, LOGIN_ROUTE } from "../../utils/router";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function ForgetPassword() {
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: { mobile: "", otp: "", password: "" },
    validationSchema: step === 1 ? ForgetPasswordSchema : ResetPasswordSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const handleSendOtp = async () => {
    if (!formik.values.mobile) {
      toast.error("Please enter your mobile number");
      return;
    }
    try {
      const response = await apiRequest.sendOtp({ mobile: formik.values.mobile });
      if (response.data && response.data.response === true) {
        toast.success("OTP sent successfully");
        setStep(2);
        setTimer(59); 
      } else {
        toast.error(response.data?.msg);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data.errors);
      }
      toast.error(error.response?.data?.msg || "An error occurred");
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await apiRequest.sendOtp({ mobile: formik.values.mobile });
      if (response.data && response.data.response === true) {
        toast.success("OTP resent successfully");
        setTimer(59);
      } else {
        toast.error(response.data?.msg);
      }
    } catch (error) {
      toast.error(error.response?.data?.msg || "An error occurred");
    }
  };

  const handleResetPassword = async () => {
    const { mobile, otp, password } = formik.values;
    if (!mobile || !otp || !password) {
      toast.error("Please fill in all fields");
      return;
    }
    try {
      const response = await apiRequest.changePassword({ mobile, otp, password });
      if (response.data && response.data.response === true) {
        toast.success("Password reset successfully");
        formik.resetForm();
        setStep(1);
        navigate(HOME_ROUTE);
      } else {
        toast.error(response.data?.msg);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data.errors);
      }
      toast.error(error.response?.data?.msg || "An error occurred");
    }
  };

  return (
    <form className="grid w-full grid-cols-1 gap-6">
      {step === 1 && (
        <>
          <h2 className="mb-4 text-2xl font-bold text-gray-800">Forget Password</h2>
          <div className="col-span-6">
            <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">
              Mobile Number
            </label>
            <Input
              type="text"
              name="mobile"
              placeholder="Enter Your Mobile No."
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.mobile}
              touched={formik.touched.mobile}
              serverError={validationError?.mobile}
            />
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <h2 className="mb-4 text-2xl font-bold text-gray-800">Reset Password</h2>
          <div className="col-span-6">
            <p className="text-sm font-medium text-gray-700">
              OTP sent to <strong>{formik.values.mobile}</strong>.{" "}
              <button
                type="button"
                className="underline text-primary-500"
                onClick={() => {
                  setStep(1);
                  formik.setFieldValue("otp", "");
                  formik.setFieldValue("password", "");
                }}
              >
                Change Mobile
              </button>
            </p>
          </div>
          <div className="col-span-6">
            <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
              OTP
            </label>
            <Input
              type="text"
              name="otp"
              placeholder="Enter OTP"
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.otp}
              touched={formik.touched.otp}
              serverError={validationError?.otp}
            />
          </div>
          <div className="col-span-6">
            <div className="relative">
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                label="New Password"
                placeholder="Enter New Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.password}
                touched={formik.touched.password}
                serverError={validationError?.password}
              />
              <button
                type="button"
                className="absolute text-gray-500 right-2 top-[30px] text-2xl"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </button>
            </div>
          </div>
          <div className="col-span-6">
            <button
              type="button"
              onClick={handleResendOtp}
              className={`inline-block px-4 py-1 text-[12px] font-medium  rounded-md ${
                timer > 0 ? "text-gray-900 cursor-not-allowed" : "text-red-500 hover:text-red-600"
              }`}
              disabled={timer > 0}
            >
              {timer > 0 ? `Resend OTP in ${timer}s` : "Resend OTP"}
            </button>
          </div>
        </>
      )}

      <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
        {step === 1 ? (
          <button
            type="button"
            onClick={handleSendOtp}
            className="inline-block px-12 py-3 text-sm font-medium text-white transition border rounded-md shrink-0 border-accent-500 bg-accent-500 hover:bg-transparent hover:text-accent-500 focus:outline-none focus:ring active:text-accent-500"
          >
            Send OTP
          </button>
        ) : (
          <button
            type="button"
            onClick={handleResetPassword}
            className="inline-block px-12 py-3 text-sm font-medium text-white transition border rounded-md shrink-0 border-accent-500 bg-accent-500 hover:bg-transparent hover:text-accent-500 focus:outline-none focus:ring active:text-accent-500"
          >
            Reset Password
          </button>
        )}
        <p className="mt-4 text-sm text-gray-500 sm:mt-0">
          Remembered your password?
          <Link to={LOGIN_ROUTE} className="mx-1 text-primary-500">
            Login Now
          </Link>
        </p>
      </div>
    </form>
  );
}
