import React, { useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { Helmet } from "react-helmet";
import ForgetPassword from "../components/auth/ForgetPassword";
import { useDispatch } from "react-redux";
import { setLoginOpen } from "../redux/headerSlice";

const ForgetPasswordPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLoginOpen(false));
    }, [dispatch])
    return (
        <div>
            <Helmet>
                <title>Forget Password - Variety Mega Store</title>
            </Helmet>
            <div className="flex items-center justify-center w-full py-5">
                <Card>
                    <CardContent>
                        <ForgetPassword />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default ForgetPasswordPage;
