import React, { useState, useEffect } from "react";
import apiRequest from "../utils/apiRequest";
import { Link } from "react-router-dom";
import Input from "../components/utils/Input";
import { toast } from "react-hot-toast";
import { cartCount, deleteFromCart, fetchCartItem } from "../redux/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createSlug,
  formatAmountToINR,
  getAssetUriWithSize,
} from "../utils/helper";
import { PRODUCT_DETAIL_ROUTE } from "../utils/router";
import { useFormik } from "formik";
import AddressCard from "../components/address/AddressCard";
import { checkOutSchema } from "../validation/checkOutSchema";
import CartEmpty from "../components/cart/CartEmpty";
import PaymentFailed from "../components/payment/PaymentFailed";
import PaymentSuccess from "../components/payment/PaymentSuccess";
import PhonePeImage from "../assets/payment/phonepe.png";
import CodImage from "../assets/payment/cod.jpg";
import { Helmet } from "react-helmet";
import authManager from "../utils/authManager";
import OrderSuccess from "../components/payment/OrderSuccess";
const Checkout = () => {
  const carts = useSelector((state) => state.headerRedux.carts);
  const dispatch = useDispatch();
  const [userAddress, setUserAddress] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const [isOrderSuccess, setIsOrderSuccess] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [debounceTimeouts, setDebounceTimeouts] = useState({});

  const selectedAddress = useSelector(
    (state) => state.orderRedux.selectedAddress
  );
  const getUserSavedAddress = async () => {
    await apiRequest
      .getUserSavedAddress()
      .then((res) => {
        setUserAddress(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteCartItem = async (variationId, productId) => {
    dispatch(
      deleteFromCart({ product_id: productId, variation_id: variationId })
    );
  };

  useEffect(() => {
    const initialQuantities = {};
    carts.forEach((item) => {
      initialQuantities[item.id] = item.quantity;
    });
    setQuantities(initialQuantities);
  }, [carts]);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      postcode: "",
      address: "",
      locality: "",
      isSaved: null,
      file: null,
    },
    validationSchema: checkOutSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (paymentMethod !== null) {
        const calculatedAmount = calculateTotal() + calculateTax();
        setLoading(
          paymentMethod === "COD"
            ? "Processing Order..."
            : "Processing Payment..."
        );
        const formData = new FormData();
        Object.entries(
          Object.assign({}, values, {
            amount: calculatedAmount,
          })
        ).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("paymentType", paymentMethod);
        try {
          const response = await apiRequest.createPaymentOrder(formData);
          if (response.data.response) {
            dispatch(fetchCartItem());
            if (response.data.paymentType === "COD") {
              setIsOrderSuccess(true);
            } else {
              authManager.storeOrderId(response.data.orderId);
              window.location.href = `${response.data.redirectUrl}`;
            }
          } else {
            toast.error(response.data.error || "An unexpected error occurred.");
          }
        } catch (error) {
          if (error.response && error.response.status === 400) {
            setValidationError(error.response.data.errors);
          }
          const errorMessage =
            error.response?.data?.error ||
            error.message ||
            "Something went wrong!";
          toast.error(errorMessage);
        } finally {
          setLoading(null);
        }
      } else {
        toast.error("Please Select Payment Method");
      }
    },
  });
  const handleQuantityChange = (cartId, value) => {
    const quantity = parseInt(value, 10) || 0;
    setQuantities((prev) => ({
      ...prev,
      [cartId]: quantity,
    }));

    if (debounceTimeouts[cartId]) {
      clearTimeout(debounceTimeouts[cartId]);
    }

    const timeout = setTimeout(() => {
      if (quantity > 0) {
        dispatch(cartCount({ cartId, quantity }));
      }
    }, 300);

    setDebounceTimeouts((prev) => ({
      ...prev,
      [cartId]: timeout,
    }));
  };
  const calculateTotal = () => {
    let total = 0;
    for (const key in carts) {
      if (carts.hasOwnProperty(key)) {
        const item = carts[key];
        if (
          item &&
          item.variation &&
          item.variation.active_price &&
          item.variation.active_price.price
        ) {
          let productPrice = item.variation.active_price.price * item.quantity;
          if (
            item.variation.active_price &&
            item.variation.active_price.discount > 0
          ) {
            let discount =
              (productPrice * item.variation.active_price.discount) / 100;
            total += productPrice - discount;
          } else {
            total += productPrice;
          }
        }
      }
    }
    return total;
  };

  const calculateDiscount = () => {
    let discount = 0;
    for (const key in carts) {
      if (carts.hasOwnProperty(key)) {
        const item = carts[key];
        if (
          item &&
          item.variation &&
          item.variation.active_price &&
          item.variation.active_price.price
        ) {
          let productPrice = item.variation.active_price.price * item.quantity;
          if (
            item.variation.active_price &&
            item.variation.active_price.discount > 0
          ) {
            discount +=
              (productPrice * item.variation.active_price.discount) / 100;
          }
        }
      }
    }
    return discount;
  };

  const calculateTax = () => {
    let total = 0;
    for (const key in carts) {
      if (carts.hasOwnProperty(key)) {
        const item = carts[key];
        if (
          item &&
          item.variation &&
          item.variation.active_price &&
          item.variation.active_price.price
        ) {
          const taxPercentage = item.variation.product.tax || 0;
          const taxAmount =
            (item.variation.active_price.price * taxPercentage) / 100;
          total += taxAmount;
        }
      }
    }
    return total;
  };

  useEffect(() => {
    if (selectedAddress) {
      formik.setValues({
        ...selectedAddress,
        isSaved: "Save",
      });
    }
  }, [selectedAddress]);

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      if (authManager.hasOrderId()) {
        try {
          const response = await apiRequest.checkPaymentStatus({
            orderId: authManager.getOrderId(),
          });
          authManager.deleteOrderId();
          if (
            response.data.response &&
            response.data.order.payment_status === "COMPLETED"
          ) {
            setIsPaymentSuccess(true);
          } else {
            setIsPaymentFailed(true);
          }
        } catch (error) {
          setIsPaymentFailed(true);
        }
      }
    };
    fetchPaymentStatus();
    getUserSavedAddress();
  }, []);
  if (isPaymentSuccess)
    return (
      <div>
        <Helmet>
          <title>Payment Success - Variety Mega Store</title>
        </Helmet>
        <PaymentSuccess />
      </div>
    );
  if (isOrderSuccess)
    return (
      <div>
        <Helmet>
          <title>Order Completed - Variety Mega Store</title>
        </Helmet>
        <OrderSuccess />
      </div>
    );

  if (isPaymentFailed)
    return (
      <div>
        <Helmet>
          <title>Payment Failed - Variety Mega Store</title>
        </Helmet>
        <PaymentFailed onClick={() => setIsPaymentFailed(false)} />
      </div>
    );
  if (carts.length === 0 || !authManager.hasAccessToken()) return <CartEmpty />;

  return (
    <>
      <Helmet>
        <title>Checkout - Variety Mega Store</title>
      </Helmet>
      <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
        <div className="px-4 pt-8">
          <p className="text-xl font-medium">Order Summary</p>
          <p className="text-base text-gray-400">
            Check your items. And select a suitable shipping method.
          </p>
          <div className="px-2 py-4 mt-3 space-y-3 bg-white border rounded-lg sm:px-6">
            <ul role="list" className="-my-6 divide-y divide-gray-200">
              {carts.map((item, index) => {
                return (
                  <li key={index} className="flex py-6">
                    <div className="flex-shrink-0 w-24 h-24 overflow-hidden border border-gray-200 rounded-md">
                      <img
                        src={getAssetUriWithSize(
                          "variations",
                          item.variation.latest_image
                            ? item.variation.latest_image.image
                            : 404,
                          300,
                          300
                        )}
                        alt="Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch."
                        className="object-cover object-center w-full h-full"
                      />
                    </div>
                    <div className="flex flex-col flex-1 ml-4">
                      <div>
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <h3>
                            <Link
                              href={PRODUCT_DETAIL_ROUTE(
                                item.variation.id,
                                encodeURIComponent(
                                  createSlug(item.variation.title)
                                )
                              )}
                            >
                              {item.variation.title}
                            </Link>
                          </h3>
                          <p className="ml-4">
                            {formatAmountToINR(
                              item.variation.active_price.price * item.quantity
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-end justify-between flex-1 text-sm">
                        <div className="flex items-center gap-2 py-1">
                          <p className="text-gray-500">Qty</p>
                          <input
                            type="number"
                            value={quantities[item.id] || ""}
                            onChange={(e) =>
                              handleQuantityChange(item.id, e.target.value)
                            }
                            className="py-1 w-[80px] pl-2 pr-[5px] border outline-none"
                          />
                        </div>
                        <div className="flex">
                          <button
                            type="button"
                            onClick={() => {
                              deleteCartItem(
                                item.variation.id,
                                item.variation.product_id
                              );
                            }}
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <p className="mt-3 text-lg font-medium">Payment Method</p>
          <form className="grid gap-3 mt-3 mb-5">
            <div className="relative">
              <input
                className="hidden peer"
                id="radio_1"
                type="radio"
                checked={paymentMethod === "Phonepe"}
                onChange={() => {
                  setPaymentMethod("Phonepe");
                }}
                name="payment_method"
              />
              <span className="box-content absolute block w-3 h-3 -translate-y-1/2 bg-white border-8 border-gray-300 rounded-full peer-checked:border-gray-700 right-4 top-1/2" />
              <label
                className="flex p-4 border border-gray-300 rounded-lg cursor-pointer select-none peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50"
                htmlFor="radio_1"
              >
                <img
                  alt="payment"
                  className="object-contain w-14"
                  src={PhonePeImage}
                  width={300}
                  height={300}
                />
                <div className="ml-5">
                  <span className="mt-2 font-semibold">Pay using Phonepay</span>
                  <p className="text-sm leading-6 text-slate-500">
                    Delivery: 2-4 Days
                  </p>
                </div>
              </label>
            </div>
          </form>

          <form className="grid gap-3 mt-3 mb-5">
            <div className="relative">
              <input
                className="hidden peer"
                id="radio_2"
                type="radio"
                checked={paymentMethod === "COD"}
                onChange={() => {
                  setPaymentMethod("COD");
                }}
                name="payment_method"
              />
              <span className="box-content absolute block w-3 h-3 -translate-y-1/2 bg-white border-8 border-gray-300 rounded-full peer-checked:border-gray-700 right-4 top-1/2" />
              <label
                className="flex p-4 border border-gray-300 rounded-lg cursor-pointer select-none peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50"
                htmlFor="radio_2"
              >
                <img
                  alt="payment"
                  className="object-contain w-14"
                  src={CodImage}
                  width={600}
                  height={600}
                />
                <div className="ml-5">
                  <span className="mt-2 font-semibold">Cash on Delivery</span>
                  <p className="text-sm leading-6 text-slate-500">
                    Delivery: 2-4 Days
                  </p>
                </div>
              </label>
            </div>
          </form>
        </div>
        <div className="px-4 pt-8 mt-10 bg-gray-50 lg:mt-0">
          <p className="text-xl font-medium">Shipping Address</p>
          <p className="text-base text-gray-400">
            Complete your order by providing your shipping address details.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <Input
                    type={"text"}
                    label={"First Name"}
                    name={"first_name"}
                    placeholder={"Enter Your First Name"}
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.first_name}
                    serverError={validationError.first_name}
                    touched={formik.touched.first_name}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    label={"Last Name"}
                    name={"last_name"}
                    placeholder={"Enter Your First Name"}
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.last_name}
                    serverError={validationError.last_name}
                    touched={formik.touched.last_name}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    label={"mobile"}
                    name={"mobile"}
                    placeholder={"Enter Your Mobile No."}
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.mobile}
                    serverError={validationError.mobile}
                    touched={formik.touched.mobile}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    name={"email"}
                    label={"email"}
                    placeholder={"Enter Your Email Address"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email}
                    serverError={validationError.email}
                    touched={formik.touched.email}
                  />
                </div>

                <div>
                  <Input
                    type={"text"}
                    name={"address"}
                    placeholder={"Enter Your Address"}
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    label={"Address"}
                    onBlur={formik.handleBlur}
                    error={formik.errors.address}
                    serverError={validationError.address}
                    touched={formik.touched.address}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    name={"locality"}
                    label={"locality"}
                    placeholder={"Enter Your locality"}
                    value={formik.values.locality}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.locality}
                    serverError={validationError.locality}
                    touched={formik.touched.locality}
                  />
                </div>
              </div>
              <div>
                <Input
                  type={"text"}
                  name={"postcode"}
                  label={"postcode"}
                  placeholder={"Enter Your postcode"}
                  value={formik.values.postcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.postcode}
                  serverError={validationError.postcode}
                  touched={formik.touched.postcode}
                />
              </div>

              <div className="flex items-center my-4">
                <input
                  id="save-address"
                  type="checkbox"
                  name="isSaved"
                  onBlur={formik.handleBlur}
                  checked={formik.values.isSaved === "Save"}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue(
                      "isSaved",
                      e.target.checked ? "Save" : null
                    );
                  }}
                  defaultValue=""
                  className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-500 focus:ring-accent-500 dark:focus:ring-accent-500 "
                />
                <label
                  htmlFor="save-address"
                  className="text-base font-medium text-gray-900 ms-2 dark:text-gray-300"
                >
                  Save Address
                </label>
              </div>
              <div className="grid grid-cols-2 gap-3 mt-3">
                <AddressCard address={userAddress} />
              </div>
              <div className="py-2 mt-6 border-t border-b">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">Subtotal:</p>
                  <p className="font-semibold text-gray-900">
                    {formatAmountToINR(calculateTotal())}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">Tax:</p>
                  <p className="font-semibold text-gray-900">
                    {formatAmountToINR(calculateTax())}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">
                    Discount Applied:
                  </p>
                  <p className="font-semibold text-gray-900">
                    {formatAmountToINR(calculateDiscount())}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">Shipping:</p>
                  <p className="font-semibold text-gray-900">
                    {formatAmountToINR(0)}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-between mt-6">
                <p className="text-sm font-medium text-gray-900">Total:</p>
                <p className="text-2xl font-semibold text-gray-900">
                  {formatAmountToINR(calculateTotal() + calculateTax())}
                </p>
              </div>
            </div>
            {loading ? (
              <button
                type="submit"
                className="w-full px-6 py-3 mt-4 mb-8 font-medium text-white rounded-md bg-accent-500"
              >
                <div
                  role="status"
                  className="flex items-center justify-center w-full "
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="ml-3">{loading}</span>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="w-full px-6 py-3 mt-4 mb-8 font-medium text-white rounded-md bg-accent-500"
              >
                Pay & Submit
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default Checkout;
