import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({
  children,
  responsiveConfig,
  sliderRef,
  slideToShow = 4,
}) => {
  const defaultResponsive = [
    { breakpoint: 1024, settings: { slidesToShow: 3 } },
    { breakpoint: 768, settings: { slidesToShow: 2 } },
    { breakpoint: 480, settings: { slidesToShow: 1 } },
  ];

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 560,
    slidesToShow: slideToShow,
    slidesToScroll: 1,
    centerMode: false,
    focusOnSelect: false,
    responsive: responsiveConfig || defaultResponsive,
  };

  return (
    <div className="relative">
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default Carousel;
