import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Shipping Policy - Variety Mega Store</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              Shipping Policy
            </strong>
          </h1>
        </div>
      </section>
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5 bg-white mt-5">
        <section>
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-1 lg:gap-16">
            <div className="lg:py-24">
              <h2 className="text-3xl font-bold sm:text-4xl">Shipping Policy</h2>
              <p className="mt-4 mb-6 text-lg text-gray-700">
                This document includes your business details and context of details we can pre-fill which you can edit and use for reference.
              </p>
              <p className="mb-4 text-lg text-gray-700">
                The orders for the user are shipped through registered domestic courier companies and/or speed post only. Orders are delivered within 7 days from the date of the order and/or payment or as per the delivery date agreed at the time of order confirmation and delivering of the shipment, subject to courier company / post office norms. 
              </p>
              <p className="mb-4 text-lg text-gray-700">
                Platform Owner shall not be liable for any delay in delivery by the courier company / postal authority. Delivery of all orders will be made to the address provided by the buyer at the time of purchase. Delivery of our services will be confirmed on your email ID as specified at the time of registration.
              </p>
              <p className="mb-4 text-lg text-gray-700">
                If there are any shipping cost(s) levied by the seller or the Platform Owner (as the case may be), the same is <strong>not refundable</strong>.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ShippingPolicy;
