import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

export const AbsoluteNextArrow = ({ onClick }) => (
  <button
    className="absolute right-0 z-10 p-2 transform -translate-y-1/2 bg-white rounded-full shadow-md top-1/2 hover:bg-gray-200 md:p-3"
    onClick={onClick}
  >
    <FaArrowRight className="text-lg" />
  </button>
);

export const AbsolutePrevArrow = ({ onClick }) => (
  <button
    className="absolute left-0 z-10 p-2 transform -translate-y-1/2 bg-white rounded-full shadow-md top-1/2 hover:bg-gray-200 md:p-3"
    onClick={onClick}
  >
    <FaArrowLeft className="text-lg" />
  </button>
);
export const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="h-[35px] w-[35px] rounded-full bg-white border flex justify-center items-center"
  >
    <FaArrowRight />
  </button>
);
export const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="h-[35px] w-[35px] rounded-full bg-white border flex justify-center items-center"
  >
    <FaArrowLeft />
  </button>
);
