const authManager = {
  setAccessToken: (accessToken) => {
    try {
      localStorage.removeItem("access_token");
      localStorage.setItem("access_token", JSON.stringify(accessToken));
    } catch (error) {
      console.error("Error setting access token:", error);
    }
  },
  getAccessToken: () => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("access_token")) {
        return JSON.parse(localStorage.getItem("access_token"));
      }
      return false;
    }
    return false;
  },
  deleteAccessToken: () => {
    localStorage.removeItem("access_token");
  },
  hasAccessToken: () => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("access_token")) {
        return !!localStorage.getItem("access_token");
      }
      return false;
    }
    return false;
  },

  hasOrderId: () => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("orderId")) {
        return !!localStorage.getItem("orderId");
      }
      return false;
    }
    return false;
  },

  deleteOrderId: () => {
    localStorage.removeItem("orderId");
  },
  getOrderId: () => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("orderId")) {
        return JSON.parse(localStorage.getItem("orderId"));
      }
      return false;
    }
    return false;
  },

  storeOrderId: (orderId) => {
    try {
      localStorage.removeItem("orderId");
      localStorage.setItem("orderId", JSON.stringify(orderId));
    } catch (error) {
      console.error("Error setting access token:", error);
    }
  },
};

export default authManager;
