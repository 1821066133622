"use client";
import React, { useState, useEffect } from "react";
import { formatAmountToINR, formatDate } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrder } from "../../redux/headerSlice";
import apiRequest from "../../utils/apiRequest";
import { toast } from "react-hot-toast";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

const MyOrder = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector(({ headerRedux }) => headerRedux);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (orders.length === 0) {
      dispatch(getUserOrder());
    }
  }, [dispatch]);

  const handleCancelClick = (order) => {
    setSelectedOrder(order);
    setDialogType("cancel");
    setOpenDialog(true);
  };

  const handleTrackClick = (order) => {
    console.log(order);
    setSelectedOrder(order);
    setDialogType("track");
    setOpenDialog(true);
  };

  const handleCancelOrder = async () => {
    setIsProcessing(true);
    try {
      const response = await apiRequest.cancelOrder(selectedOrder.id);
      if (response.data && response.data.response === true) {
        toast.success("Order canceled successfully!");
        dispatch(getUserOrder());
      } else {
        toast.error(response.data?.msg || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.response?.data?.msg || "An error occurred");
    } finally {
      setOpenDialog(false);
      setIsProcessing(false);
    }
  };

  return (
    orders && (
      <div className="p-6">
        <h1 className="mb-6 text-2xl font-bold text-gray-800">My Orders</h1>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {orders.map((item, index) => (
            <div
              key={index}
              className={`overflow-hidden bg-white border rounded-lg shadow-md ${item?.latest_status?.status === "Canceled" ? "bg-red-100" : ""
                }`}
            >
              <div className="p-4">
                <h2 className="mb-2 text-sm font-semibold text-gray-800">
                  {item.products?.length > 0
                    ? `${item.products[0]?.variation?.title} (${item.products.length})`
                    : "No products available"}
                </h2>
                <p className="mb-2 text-sm text-gray-500">
                  Date: {formatDate(item.created_at)}
                </p>
                <p className="text-sm">
                  Grand Total: <span className="font-medium">{item.total_amount}</span>
                </p>
                <p className="text-sm">
                  Paid Amount: <span className="font-medium">{item.paid_amount}</span>
                </p>
                <p className="mt-1 text-sm">
                  Status:{" "}
                  <span
                    className={`px-[5px] py-[5px] rounded text-white text-[12px] ${item?.latest_status?.status === "Canceled"
                      ? "bg-red-500"
                      : "bg-gray-500"
                      }`}
                  >
                    {item?.latest_status?.status}
                  </span>
                </p>

                {item?.latest_status?.status !== "Canceled" && (
                  <div className="flex gap-2 mt-4">
                    <button
                      className="px-2 py-1 text-sm font-medium text-white bg-red-500 rounded hover:bg-red-600"
                      onClick={() => handleCancelClick(item)}
                    >
                      Cancel Order
                    </button>
                    <button
                      className="px-2 py-1 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600"
                      onClick={() => handleTrackClick(item)}
                    >
                      Track Order
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Dialog */}
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth={dialogType === 'cancel' ? 'xs' : 'sm'}
          fullWidth
        >
          {dialogType === "cancel" ? (
            <>
              <DialogTitle>Confirm Cancellation</DialogTitle>
              <DialogContent>
                Are you sure you want to cancel this order?
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDialog(false)}
                  variant="outlined"
                  color="primary"
                >
                  No
                </Button>
                <Button
                  disabled={isProcessing}
                  onClick={!isProcessing && handleCancelOrder}
                  variant="contained"
                  color="error"
                >
                  Yes, Cancel
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle>Order Tracking</DialogTitle>
              <DialogContent>

                <div className="space-y-6">
                  {/* Products Section */}
                  <div className="p-4 text-white bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-xl">
                    <p className="text-xl font-bold">PRODUCTS:</p>
                    <ul className="pl-5 space-y-1 text-sm list-disc">
                      {selectedOrder?.products.length > 0 ? (
                        selectedOrder.products.map((item, index) => (
                          <li key={index} className="font-medium">
                            {item.variation?.title || 'No product title'}
                          </li>
                        ))
                      ) : (
                        <li className="text-gray-300">No products available</li>
                      )}
                    </ul>
                  </div>

                  {/* Payment and Status in One Row */}
                  <div className="flex flex-col justify-between space-x-4 md:flex-row">
                    {/* Payment Information */}
                    <div className="w-full p-4 space-y-2 text-sm bg-gray-100 shadow-md md:w-1/2 rounded-xl">
                      <p className="text-lg font-semibold text-gray-800">Payment Details</p>
                      <div className="space-y-2">
                        <p className="flex items-center text-gray-700">
                          <span className="mr-2 text-indigo-600">💳</span>
                          <span className="font-semibold">Payment Method:</span> {selectedOrder?.payment_method}
                        </p>
                        <p className="flex items-center text-gray-700">
                          <span className="mr-2 text-indigo-600">💰</span>
                          <span className="font-semibold">Sub Total:</span> {formatAmountToINR(selectedOrder?.sub_total)}
                        </p>
                        <p className="flex items-center text-gray-700">
                          <span className="mr-2 text-indigo-600">📊</span>
                          <span className="font-semibold">Tax:</span> {formatAmountToINR(selectedOrder?.tax)}
                        </p>
                        <p className="flex items-center text-gray-700">
                          <span className="mr-2 text-indigo-600">💸</span>
                          <span className="font-semibold">Grand Total:</span> {formatAmountToINR(selectedOrder?.total_amount)}
                        </p>
                        <p className="flex items-center text-gray-700">
                          <span className="mr-2 text-indigo-600">🎟️</span>
                          <span className="font-semibold">Discount:</span> {formatAmountToINR(selectedOrder?.discount)}
                        </p>
                        <p className="flex items-center text-gray-700">
                          <span className="mr-2 text-indigo-600">💵</span>
                          <span className="font-semibold">Paid Amount:</span> {formatAmountToINR(selectedOrder?.paid_amount)}
                        </p>
                        <p className="flex items-center text-gray-700">
                          <span className="mr-2 text-indigo-600">💳</span>
                          <span className="font-semibold">Dues Amount:</span> {formatAmountToINR(selectedOrder?.total_amount - selectedOrder?.paid_amount)}
                        </p>
                        <p className="flex items-center text-gray-700">
                          <span className="mr-2 text-indigo-600">✅</span>
                          <span className="font-semibold">Payment Status:</span> {selectedOrder?.payment_status}
                        </p>
                      </div>
                    </div>

                    {/* Order Status Information */}
                    <div className="w-full p-4 space-y-2 text-sm bg-gray-200 shadow-md md:w-1/2 rounded-xl">
                      <p className="text-lg font-semibold text-gray-800">Order Status</p>
                      <ul className="pl-5 space-y-1 text-gray-700 list-disc">
                        {selectedOrder?.status.length > 0 ? (
                          selectedOrder.status.map((statusItem, index) => (
                            <li key={index} className="font-medium">
                              <span className="font-semibold">{statusItem.status}</span> - {formatDate(statusItem.created_at)}
                            </li>
                          ))
                        ) : (
                          <li className="text-gray-500">No status updates available</li>
                        )}
                      </ul>
                    </div>
                  </div>

                  {/* Address Information */}
                  <div className="p-4 text-white bg-gradient-to-r from-green-400 via-teal-400 to-blue-400 rounded-xl">
                    <p className="text-xl font-bold">Address:</p>
                    <p className="text-sm">{selectedOrder?.address}, {selectedOrder?.locality}, {selectedOrder?.postcode}</p>
                  </div>
                </div>

              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDialog(false)}
                  variant="contained"
                  color="primary"
                >
                  Close
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </div>
    )
  );
};

export default MyOrder;
