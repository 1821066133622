import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div>
      <Helmet>
        <title>About Us - Variety Mega Store</title>
      </Helmet>

      {/* Banner Section */}
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">About Us</strong>
          </h1>
        </div>
      </section>

      <div className="mx-auto max-w-[1380px] p-6 mb-5 bg-white mt-5">

        {/* About Us Section */}
        <section className="mb-10">
          <h2 className="mb-6 text-3xl font-bold sm:text-4xl">About Us</h2>
          <p className="mb-4 text-lg text-gray-700">
            Stationery art and craft typically refer to creative projects and
            designs that use stationery materials (paper, pens, markers, stickers,
            ribbons, etc.) to make artistic and decorative items. These can range
            from simple DIY crafts to intricate works of art. Below is a description
            of common types of stationery art and craft:
          </p>

          <ul className="mb-4 ml-6 text-lg text-gray-700 list-disc">
            <li>
              <strong>Handmade Cards</strong> - Cards made using paper, stamps,
              ink, embellishments, and sometimes 3D elements like ribbons or flowers.
              Materials: Cardstock, colored paper, stamps, ink pads, markers, embellishments like buttons or glitter.
            </li>
            <li>
              <strong>Scrapbooking</strong> - Creating personalized photo albums with
              decorative layouts, journaling, and embellishments. Materials: Scrapbook albums, patterned paper, die-cuts, washi tape, stamps, markers, stickers, and photos.
            </li>
            <li>
              <strong>Journaling & Bullet Journals</strong> - Writing or drawing in a notebook to express thoughts and ideas. Bullet journaling combines creativity with productivity. Materials: Notebooks, pens, colored markers, washi tapes, stickers, stamps.
            </li>
            <li>
              <strong>Calligraphy and Lettering</strong> - Decorative forms of writing using specialized tools like nib pens, brushes, or markers. Materials: Calligraphy pens, brush pens, ink, markers, and smooth paper.
            </li>
            <li>
              <strong>Paper Crafting</strong> - Creating three-dimensional or intricate designs using paper. Techniques include origami, quilling, or papercutting. Materials: Paper, scissors, glue, and sometimes specialized tools like quilling tools or cutting machines.
            </li>
            <li>
              <strong>Gift Wrapping and Tags</strong> - Wrapping gifts in decorative paper and creating personalized gift tags. Materials: Gift wrapping paper, twine, stamps, markers, tags, and embellishments like bows or sequins.
            </li>
            <li>
              <strong>Postcards & Envelopes</strong> - Designing custom postcards and envelopes. Materials: Postcard templates, stamps, ink, watercolor, markers, and embellishments.
            </li>
            <li>
              <strong>DIY Stickers and Decals</strong> - Designing personalized stickers and decals. Materials: Sticker paper, cutting machines, colored markers or pens, vinyl, and transfer paper.
            </li>
          </ul>

          <p className="mb-4 text-lg text-gray-700">
            Stationery art and crafts encourage creativity and personalization, and
            they can range from simple, fun projects to intricate, professional-looking
            designs. Whether for personal use, gifts, or cards, these crafts bring a
            unique and artistic touch to everyday stationery items.
          </p>
        </section>

        {/* Founder Message Section */}
        <section className="mb-10">
          <h2 className="mb-6 text-3xl font-bold sm:text-4xl">Founder Message</h2>
          <p className="mb-4 text-lg text-gray-700">
            Welcome to the world of creativity, where stationery becomes not just a tool,
            but a medium to express your ideas, emotions, and imagination.
          </p>
          <p className="mb-4 text-lg text-gray-700">
            As the founder of this stationery brand, Variety Stationery, I am deeply
            passionate about the power of art and craft to bring people together, inspire
            innovation, and help individuals discover their inner creativity. From the
            moment I started my own journey in the world of stationery, I realized that
            these simple materials hold the potential to transform thoughts into tangible creations.
          </p>
          <p className="mb-4 text-lg text-gray-700">
            At the heart of our mission is the belief that art should be accessible to everyone.
            We provide a wide range of carefully curated, high-quality stationery products
            designed to meet the needs of both beginners and seasoned artists. But we are
            more than just a brand – we are a community. A community where individuals of
            all backgrounds can come together to share ideas, learn new skills, and enjoy
            the therapeutic benefits of crafting and creativity.
          </p>
          <p className="mb-4 text-lg text-gray-700">
            Our stationery line is born out of a passion to encourage everyone to create,
            whether it’s through journaling, drawing, crafting, or sending heartfelt messages
            through a handwritten note. We believe that by embracing creativity, you’re not
            only enhancing your own life but also spreading joy and inspiration to those around you.
          </p>
          <p className="mb-4 text-lg text-gray-700">
            We are committed to sustainability, providing eco-friendly products without compromising
            on quality, so you can craft with a clear conscience. Your creations matter, and we’re
            here to ensure you have everything you need to bring your visions to life.
          </p>
          <p className="mb-4 text-lg text-gray-700">
            Thank you for choosing to embark on this creative journey with us. Together, let’s make
            the world a little more colorful, thoughtful, and inspired, one page at a time.
          </p>
          <p className="mb-4 text-lg text-gray-700">
            With gratitude and creativity,
            <br />
            FOUNDER & CEO – MR. KHEMRAJ U JAT
          </p>
        </section>

        {/* Mission Section */}
        <section className="mb-10">
          <h2 className="mb-6 text-3xl font-bold sm:text-4xl">Our Mission</h2>
          <p className="mb-4 text-lg text-gray-700">
            To inspire creativity and self-expression through high-quality, accessible
            art and craft stationery. We aim to provide individuals, educators, and artists
            with the tools they need to create, explore, and develop their artistic potential.
          </p>
          <p className="mb-4 text-lg text-gray-700">
            Our mission is to foster a love for crafting and artistic expression by offering
            a wide range of stationery materials that encourage imagination, hands-on learning,
            and a sense of accomplishment.
          </p>
          <h3 className="mb-4 text-2xl font-semibold text-gray-700">Key Objectives</h3>
          <ul className="ml-6 text-lg text-gray-700 list-disc">
            <li>Encourage Creativity: Provide artists, crafters, and hobbyists with the materials needed to create unique works of art.</li>
            <li>Support Learning and Development: Promote educational opportunities in art and craft through schools, workshops, or online platforms.</li>
            <li>Make Crafting Accessible: Ensure that art and craft supplies are affordable and easy to access for everyone.</li>
            <li>Sustainability and Eco-Friendly Products: Promote environmentally friendly materials and reduce waste by offering eco-conscious stationery options.</li>
            <li>Build a Community of Artists: Foster a community where individuals can share their creativity, ideas, and projects.</li>
            <li>Inspire and Motivate: Provide inspiration through guides, tutorials, and curated craft kits to help individuals take on new challenges.</li>
            <li>Celebrate Art in Everyday Life: Help people incorporate art into their daily lives, from personal journals to home decor and gift-giving.</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default About;
