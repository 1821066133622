import Hero from "../components/home/Hero";
import { FaProductHunt } from "react-icons/fa6";
import { FaUserClock } from "react-icons/fa";
import { SiRoamresearch, SiIntigriti } from "react-icons/si";
import { flterProduct } from "../redux/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerPostItem } from "react-shimmer-effects";
import { useState } from "react";
import SaleOffer from "../assets/offer/sale-offer.jpg";
import Offer1 from "../assets/offer/offer-1.jpg";
import Offer2 from "../assets/offer/offer-2.jpg";
import Offer3 from "../assets/offer/offer-3.jpg";
import Container from "../components/ui/container/Container";
import Heading from "../components/ui/heading/Heading";
import Carousel from "../components/ui/carousel/Carousel";
import { Helmet } from "react-helmet";
import useCarousel from "../hooks/useCarousel";
import { NextArrow, PrevArrow } from "../components/ui/carousel/CarouselButton";
import { CategoryFilter } from "../components/product/Filter";
import ProductBuilder from "../components/product/ProductBuilder";
function Home() {
  const [activeCategory, setActiveCategory] = useState(0);
  const {
    art,
    office,
    diy,
    products,
    mixed,
    top_rated_product,
    best_seller_product,
    isProductFiltering,
    filterProducts,
    homeDataloaded,
  } = useSelector(({ headerRedux }) => headerRedux);
  const dispatch = useDispatch();
  const { sliderRef: testomonialRef, next, prev } = useCarousel();
  const {
    sliderRef: mixedRef,
    next: mixedNext,
    prev: mixedPrev,
  } = useCarousel();

  const { sliderRef: artRef, next: artNext, prev: artPrev } = useCarousel();
  const {
    sliderRef: officeRef,
    next: officeNext,
    prev: officePrev,
  } = useCarousel();
  const { sliderRef: diyRef, next: diyNext, prev: diyPrev } = useCarousel();
  const { sliderRef: topRef, next: topNext, prev: topPrev } = useCarousel();
  const { sliderRef: mostRef, next: mostNext, prev: mostPrev } = useCarousel();
  const filterHomeProduct = (index) => {
    setActiveCategory(index);
    let filter = null;
    if (index === 0) {
      filter = "NEW";
    } else if (index === 1) {
      filter = "FEATURED";
    }
    dispatch(flterProduct(filter));
  };
  return (
    <>
      <Helmet>
        <title>Variety Mega Store</title>
      </Helmet>
      <Hero />

      {!homeDataloaded ||
        (filterProducts?.length > 0 && (
          <Container>
            <div className="flex items-start justify-between">
              <div className="space-y-5">
                <Heading text={"Featured Products"} />
                <CategoryFilter
                  selectedItem={activeCategory}
                  carCategories={[
                    { name: "NEW PRODUCTS" },
                    { name: "FEATURED PRODUCTS" },
                    { name: "PRODUCTS ON SALE" },
                  ]}
                  onClick={filterHomeProduct}
                />
              </div>
              <div className="flex items-center gap-4">
                <PrevArrow onClick={prev} />
                <NextArrow onClick={next} />
              </div>
            </div>
            <div className="border-2 border-b"></div>
            <div className="mt-4">
              <Carousel sliderRef={testomonialRef} slideToShow={5}>
                {isProductFiltering || !homeDataloaded
                  ? Array.from({ length: 8 }, (_, index) => index).map(
                      (item, index) => {
                        return (
                          <div className="px-2" key={index}>
                            <ShimmerPostItem card title imageHeight={200} />
                          </div>
                        );
                      }
                    )
                  : filterProducts.map((product, index) => (
                      <div key={index}>
                        <ProductBuilder product={product} key={index} />
                      </div>
                    ))}
              </Carousel>
            </div>
          </Container>
        ))}

      {!homeDataloaded ||
        (art?.length > 0 && (
          <Container>
            <div className="flex items-center justify-between  px-4 py-3 bg-white rounded-[5px]">
              <Heading text={"MIXED MEDIA ART"} />
              <div className="flex items-center gap-4">
                <PrevArrow onClick={artPrev} />
                <NextArrow onClick={artNext} />
              </div>
            </div>
            <div className="mt-4">
              <Carousel sliderRef={artRef} slideToShow={5}>
                {!homeDataloaded
                  ? Array.from({ length: 8 }, (_, index) => index).map(
                      (item, index) => {
                        return (
                          <div className="px-2" key={index}>
                            <ShimmerPostItem card title imageHeight={200} />
                          </div>
                        );
                      }
                    )
                  : art.map((product, index) => (
                      <div key={index}>
                        <ProductBuilder product={product} key={index} />
                      </div>
                    ))}
              </Carousel>
            </div>
          </Container>
        ))}

      {!homeDataloaded ||
        (mixed?.length > 0 && (
          <Container>
            <div className="flex items-center justify-between px-4 py-3 bg-white rounded-[5px]">
              <Heading text={"MIXED MEDIA ART"} />
              <div className="flex items-center gap-4">
                <PrevArrow onClick={mixedPrev} />
                <NextArrow onClick={mixedNext} />
              </div>
            </div>
            <div className="mt-4">
              <Carousel sliderRef={mixedRef} slideToShow={5}>
                {!homeDataloaded
                  ? Array.from({ length: 8 }, (_, index) => index).map(
                      (item, index) => {
                        return (
                          <div className="px-2" key={index}>
                            <ShimmerPostItem card title imageHeight={200} />
                          </div>
                        );
                      }
                    )
                  : mixed.map((product, index) => (
                      <div key={index}>
                        <ProductBuilder product={product} key={index} />
                      </div>
                    ))}
              </Carousel>
            </div>
          </Container>
        ))}

      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
        <div className="h-48 my-16 overflow-hidden" data-aos="zoom-in">
          <div
            className="container rounded-[10px] aspect-square"
            style={{
              backgroundImage: `url('${SaleOffer}')`,
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </div>

      {!homeDataloaded ||
        (office?.length > 0 && (
          <Container>
            <div className="flex items-center justify-between  px-4 py-3 bg-white rounded-[5px]">
              <Heading text={"OFFICE STATIONERY"} />
              <div className="flex items-center gap-4">
                <PrevArrow onClick={officePrev} />
                <NextArrow onClick={officeNext} />
              </div>
            </div>
            <div className="mt-4">
              <Carousel sliderRef={officeRef} slideToShow={5}>
                {!homeDataloaded
                  ? Array.from({ length: 8 }, (_, index) => index).map(
                      (item, index) => {
                        return (
                          <div className="px-2" key={index}>
                            <ShimmerPostItem card title imageHeight={200} />
                          </div>
                        );
                      }
                    )
                  : office.map((product, index) => (
                      <div key={index}>
                        <ProductBuilder product={product} key={index} />
                      </div>
                    ))}
              </Carousel>
            </div>
          </Container>
        ))}
      {!homeDataloaded ||
        (diy?.length > 0 && (
          <Container>
            <div className="flex items-center justify-between  px-4 py-3 bg-white rounded-[5px]">
              <Heading text={"DIY"} />
              <div className="flex items-center gap-4">
                <PrevArrow onClick={diyPrev} />
                <NextArrow onClick={diyNext} />
              </div>
            </div>
            <div className="mt-4">
              <Carousel sliderRef={diyRef} slideToShow={5}>
                {!homeDataloaded
                  ? Array.from({ length: 8 }, (_, index) => index).map(
                      (item, index) => {
                        return (
                          <div className="px-2" key={index}>
                            <ShimmerPostItem card title imageHeight={200} />
                          </div>
                        );
                      }
                    )
                  : diy.map((product, index) => (
                      <div key={index}>
                        <ProductBuilder product={product} key={index} />
                      </div>
                    ))}
              </Carousel>
            </div>
          </Container>
        ))}

      <Container>
        <div className="relative px-4 py-3 bg-white rounded ">
          <Heading text={"Best Deals on Products"} />
        </div>
        <div className="grid gap-5 mt-6 xxs:grid-cols-2 md:grid-cols-4">
          <div
            data-aos="fade-up-left"
            className="relative gap-5 bg-white rounded-lg primaryShadow"
          >
            <div className="overflow-hidden rounded">
              <img
                src={Offer1}
                className="object-cover w-full h-full duration-300 hover:scale-110"
              />
            </div>
          </div>

          <div
            data-aos="fade-up-left"
            className="relative gap-5 bg-white rounded-lg primaryShadow"
          >
            <div className="overflow-hidden rounded ">
              <img
                src={Offer2}
                className="object-cover w-full h-full duration-300 hover:scale-110"
              />
            </div>
          </div>

          <div
            data-aos="fade-up-left"
            className="relative gap-5 bg-white rounded-lg primaryShadow"
          >
            <div className="overflow-hidden rounded">
              <img
                src={Offer3}
                className="object-cover w-full h-full duration-300 hover:scale-110"
              />
            </div>
          </div>

          <div
            data-aos="fade-up-left"
            className="relative gap-5 bg-white rounded-lg primaryShadow"
          >
            <div className="overflow-hidden rounded">
              <img
                src={Offer1}
                className="object-cover w-full h-full duration-300 hover:scale-110"
              />
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="flex items-center justify-between  px-4 py-3 bg-white rounded-[5px]">
          <Heading text={"TOP RATED PRODUCTS"} />
          <div className="flex items-center gap-4">
            <PrevArrow onClick={topPrev} />
            <NextArrow onClick={topNext} />
          </div>
        </div>
        <div className="my-4">
          <Carousel sliderRef={topRef} slideToShow={5}>
            {!homeDataloaded
              ? Array.from({ length: 8 }, (_, index) => index).map(
                  (item, index) => {
                    return (
                      <div className="px-2" key={index}>
                        <ShimmerPostItem card title imageHeight={200} />
                      </div>
                    );
                  }
                )
              : (top_rated_product.length > 4
                  ? top_rated_product
                  : products
                ).map((product, index) => (
                  <div key={index}>
                    <ProductBuilder product={product} key={index} />
                  </div>
                ))}
          </Carousel>
        </div>
      </Container>

      <Container>
        <div className="flex items-center justify-between  px-4 py-3 bg-white rounded-[5px]">
          <Heading text={"MOST SELLING TODAY"} />
          <div className="flex items-center gap-4">
            <PrevArrow onClick={mostPrev} />
            <NextArrow onClick={mostNext} />
          </div>
        </div>
        <div className="my-4">
          <Carousel sliderRef={mostRef} slideToShow={5}>
            {!homeDataloaded
              ? Array.from({ length: 8 }, (_, index) => index).map(
                  (item, index) => {
                    return (
                      <div className="px-2" key={index}>
                        <ShimmerPostItem card title imageHeight={200} />
                      </div>
                    );
                  }
                )
              : (best_seller_product.length > 4
                  ? best_seller_product
                  : products
                ).map((product, index) => (
                  <div key={index}>
                    <ProductBuilder product={product} key={index} />
                  </div>
                ))}
          </Carousel>
        </div>
      </Container>
      <div className="px-5 py-3 bg-white rounded ">
        <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
          Core Values
        </h3>

        <div className="grid flex-wrap grid-cols-1 gap-5 py-5 bg-white lg:grid-cols-4">
          <div className="flex flex-col items-center justify-center gap-3">
            <FaProductHunt className="text-5xl text-primary-500" />
            <p className="uppercase">Search For Product</p>
            <p className="text-xs -[250px] text-center">
              Search For Product i.e. Pen, Stationary or any other Product.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-3">
            <FaUserClock className="text-5xl text-primary-500" />
            <p className="uppercase">Customer Focus</p>
            <p className="text-xs max-w-[250px] text-center">
              Delivering tailored solutions to Customer client needs.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-3">
            <SiIntigriti className="text-5xl text-primary-500" />
            <p className="uppercase">Express Delivery</p>
            <p className="text-xs max-w-[250px] text-center">
              Swift shipping ensures your items arrive promptly.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-3">
            <SiRoamresearch className="text-5xl text-primary-500" />
            <p className="uppercase">Sustainability</p>
            <p className="text-xs max-w-[250px] text-center">
              Minimizing environmental footprint.
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 py-6 text-black bg-dutchwhite">
        <div className="flex flex-col items-center justify-between max-w-screen-lg gap-6 mx-auto sm:flex-row">
          <div className="sm:w-7/12">
            <div className="text-3xl font-bold">Our Vision</div>
            <p className="mt-3 text-sm text-slate-600">
              To empower individuals and businesses with a diverse range of
              stationery that combines quality, creativity, and sustainability.
              At Variety Stationery, we envision a world where every idea starts
              with the perfect tool and every workspace reflects inspiration.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
