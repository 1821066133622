import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createSlug,
  formatAmountToINR,
  getAssetUriWithSize,
} from "../../utils/helper";
import { CHECKOUT_ROUTE, PRODUCT_DETAIL_ROUTE } from "../../utils/router";
import {
  deleteFromCart,
  cartCount,
} from "../../redux/headerSlice";
import { MdShoppingCartCheckout } from "react-icons/md";
import { Button } from "@mui/material";

const Cart = ({ toggleCart }) => {
  const carts = useSelector((state) => state.headerRedux.carts);
  const dispatch = useDispatch();
  const [quantities, setQuantities] = useState({});
  const [debounceTimeouts, setDebounceTimeouts] = useState({});

  useEffect(() => {
    const initialQuantities = {};
    carts.forEach((item) => {
      initialQuantities[item.id] = item.quantity;
    });
    setQuantities(initialQuantities);
  }, [carts]);

  const calculateTotal = () => {
    return carts.reduce((total, item) => {
      const price = item.variation.active_price.price * item.quantity;
      const discount =
        (price * item.variation.active_price.discount) / 100 || 0;
      return total + (price - discount);
    }, 0);
  };
  const handleQuantityChange = (cartId, value) => {
    const quantity = parseInt(value, 10) || 0;
    setQuantities((prev) => ({
      ...prev,
      [cartId]: quantity,
    }));

    if (debounceTimeouts[cartId]) {
      clearTimeout(debounceTimeouts[cartId]);
    }

    const timeout = setTimeout(() => {
      if (quantity > 0) {
        dispatch(cartCount({ cartId, quantity }));
      }
    }, 300);

    setDebounceTimeouts((prev) => ({
      ...prev,
      [cartId]: timeout,
    }));
  };

  const handleDelete = (productId, variationId) => {
    dispatch(
      deleteFromCart({ product_id: productId, variation_id: variationId })
    );
  };

  return (
    <div className="w-screen max-w-md">
      <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
        <div className="flex-1 px-4 py-6 overflow-y-auto sm:px-6">
          <div className="flex items-start justify-between">
            <h2
              className="text-lg font-medium text-gray-900"
              id="slide-over-title"
            >
              Shopping cart
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                onClick={toggleCart}
                type="button"
                className="relative p-2 -m-2 text-gray-400 bg-slate-200"
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Close panel</span>
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mt-8">
            <div className="flow-root">
              <ul role="list" className="-my-6 divide-y divide-gray-200">
                {carts.map((item, index) => (
                  <li key={index} className="flex py-6">
                    <div className="flex-shrink-0 w-24 h-24 overflow-hidden border border-gray-200 rounded-md">
                      <img
                        src={getAssetUriWithSize(
                          "variations",
                          item.variation.latest_image
                            ? item.variation.latest_image.image
                            : 404,
                          300,
                          300
                        )}
                        alt="Product Image"
                        className="object-cover object-center w-full h-full"
                      />
                    </div>
                    <div className="flex flex-col flex-1 ml-4">
                      <div>
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <h3>
                            <Link
                              to={PRODUCT_DETAIL_ROUTE(
                                item.variation.id,
                                encodeURIComponent(
                                  createSlug(item.variation.title)
                                )
                              )}
                            >
                              {item.variation.title}
                            </Link>
                          </h3>
                          <p className="ml-4">
                            {formatAmountToINR(
                              item.variation.active_price.price * item.quantity
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-end justify-between flex-1 text-sm">
                        <div className="flex items-center gap-2 py-1">
                          <p className="text-gray-500">Qty</p>
                          <input
                            type="number"
                            value={quantities[item.id] || ""}
                            onChange={(e) =>
                              handleQuantityChange(item.id, e.target.value)
                            }
                            className="py-1 w-[80px] pl-2 pr-[5px] border outline-none"
                          />
                        </div>
                        <div className="flex">
                          <button
                            type="button"
                            onClick={() =>
                              handleDelete(
                                item.variation.product_id,
                                item.variation.id
                              )
                            }
                            className="font-medium text-primary-600 hover:text-primary-500"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="px-4 py-6 border-t border-gray-200 sm:px-6">
          <div className="flex justify-between text-base font-medium text-gray-900">
            <p>Subtotal</p>
            <p>{formatAmountToINR(calculateTotal())}</p>
          </div>
          <p className="mt-0.5 text-sm text-gray-500">
            Shipping and taxes calculated at checkout.
          </p>
          <div className="mt-6">
            <Link to={CHECKOUT_ROUTE}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                className="w-full"
                tabIndex={-1}
                startIcon={<MdShoppingCartCheckout />}
              >
                Checkout
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
