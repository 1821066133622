import { useRef } from "react";

const useCarousel = () => {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const prev = () => {
    sliderRef.current?.slickPrev();
  };

  return { sliderRef, next, prev };
};

export default useCarousel;
