import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";

const RefundPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Refund Policy - Variety Mega Store</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              Refund Policy
            </strong>
          </h1>
        </div>
      </section>
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5 bg-white mt-5">
        <section>
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-1 lg:gap-16">
            <div className="lg:py-24">
              <h2 className="text-3xl font-bold sm:text-4xl">
                Refund and Cancellation Policy
              </h2>
              <p className="mt-4 mb-6 text-lg text-gray-700">
                This refund and cancellation policy outlines how you can cancel or seek a refund for a product/service that you have purchased through the Platform. Under this policy:
              </p>
              <p className="mb-4 text-lg text-gray-700">
                <strong>i.</strong> Cancellations will only be considered if the request is made within 7 days of placing the order. However, cancellation requests may not be entertained if the orders have been communicated to the sellers/merchants listed on the Platform, and they have initiated the process of shipping or the product is out for delivery. In such an event, you may choose to reject the product at the doorstep.
              </p>
            
              <p className="mb-4 text-lg text-gray-700">
                <strong>iii.</strong> In case of receipt of damaged or defective items, please report to our customer service team. The request would be entertained once the seller/merchant listed on the Platform has checked and determined the same at their end. This should be reported within 7 days of receipt of the products. If the product received is not as shown on the site or does not meet your expectations, you must notify our customer service within 7 days of receiving the product. The customer service team will then take an appropriate decision.
              </p>
              <p className="mb-4 text-lg text-gray-700">
                <strong>iv.</strong> For complaints regarding products that come with a warranty from the manufacturers, please refer the issue to them.
              </p>
              <p className="mb-4 text-lg text-gray-700">
                <strong>v.</strong> In case of any refunds approved by VARIETY BOOK CENTRE AND STATIONERS, it will take 7 days for the refund to be credited to you.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RefundPolicy;
