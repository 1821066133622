import React, { useState } from "react";
import Input from "../components/utils/Input";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  HOME_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITION_ROUTE,
} from "../utils/router";
import { RegisterFormSchema } from "../validation/authFormSchema";
import { useFormik } from "formik";
import apiRequest from "../utils/apiRequest";
import { setProfileData } from "../redux/headerSlice";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import authManager from "../utils/authManager";
import RegisterImage from "../assets/auth/register.jpg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
const Register = () => {
  const [validationError, setValidationError] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    otp: "",
    confirm_password: "",
  });
  const dispatch = useDispatch();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleOtp = async () => {
    try {
      const response = await apiRequest.sendOTP({
        mobile: formik.values.mobile,
      });
      if (response.data.response) {
        setIsOtpSent(true);
        toast.success(response.data?.msg);
      } else {
        toast.error(response.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      password: "",
      otp: "",
      confirm_password: "",
    },
    validationSchema: RegisterFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await apiRequest.registerUser(values);
        if (response.data.response) {
          setLoading(false);
          toast.success(response.data?.msg);
          authManager.setAccessToken(response.data.token);
          dispatch(setProfileData(response.data.profile));
          formik.resetForm();
          navigate(HOME_ROUTE);
        } else {
          toast.error(response.data?.msg);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setValidationError(error.response.data.errors);
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div>
      <Helmet>
        <title>Register - Variety Mega Store</title>
      </Helmet>
      <section className="bg-white">
        <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
          <section className="relative flex items-end h-32 bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6">
            <img
              alt="stationary"
              src={RegisterImage}
              className="absolute inset-0 object-cover w-full h-full opacity-80"
            />

            <div className="hidden lg:relative lg:block lg:p-12">
              <Link className="block text-white" to={HOME_ROUTE}>
                <span className="sr-only">Home</span>
              </Link>

              <h2 className="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                Welcome to Variety Mega Store
              </h2>

              <p className="mt-4 leading-relaxed text-white/90">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa
                sit rerum incidunt, a consequuntur recusandae ab saepe illo est
                quia obcaecati neque quibusdam eius accusamus error officiis
                atque voluptates magnam!
              </p>
            </div>
          </section>
          <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
            <div className="max-w-xl lg:max-w-3xl">
              <div className="relative block -mt-16 lg:hidden">
                <div className="flex items-center justify-center text-blue-600 bg-white rounded-full size-16 sm:size-20">
                  <img src="/logo-white.png" />
                </div>

                <h1 className="mt-2 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                  Welcome to Variety Mega Store
                </h1>

                <p className="mt-4 leading-relaxed text-gray-500">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Eligendi nam dolorum aliquam, quibusdam aperiam voluptatum.
                </p>
              </div>

              <form
                onSubmit={formik.handleSubmit}
                className="grid grid-cols-6 gap-6 mt-8"
              >
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="FirstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name
                  </label>
                  <Input
                    type={"text"}
                    name={"name"}
                    placeholder={"Enter Your Name"}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    serverError={validationError.name}
                    touched={formik.touched.name}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="LastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <Input
                    type={"email"}
                    name={"email"}
                    placeholder={"Enter Your Email"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email}
                    serverError={validationError.email}
                    touched={formik.touched.email}
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="mobile"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobile
                  </label>
                  <div className="relative">
                    <Input
                      type={"text"}
                      name={"mobile"}
                      disabled={isOtpSent}
                      placeholder={"Enter Your Mobile No."}
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.mobile}
                      serverError={validationError.mobile}
                      touched={formik.touched.mobile}
                    />
                    <div className="absolute text-base top-3 right-3">
                      <div
                        onClick={handleOtp}
                        className="cursor-pointer hover:text-accent-500"
                      >
                        {isOtpSent ? "Re-send OTP" : "Send OTP"}
                      </div>
                    </div>
                  </div>
                </div>
                {isOtpSent && (
                  <div className="col-span-6">
                    <p className="mb-2 text-sm font-medium text-gray-700">
                      OTP sent to <strong>{formik.values.mobile}</strong>.{" "}
                      <button
                        type="button"
                        className="underline text-primary-500"
                        onClick={() => {
                          setIsOtpSent(false);
                        }}
                      >
                        Change Mobile
                      </button>
                    </p>
                    <label
                      htmlFor="Email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Enter OTP
                    </label>
                    <Input
                      type={"text"}
                      name={"otp"}
                      placeholder={"Enter 6 Digit OTP"}
                      value={formik.values.otp}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.otp}
                      serverError={validationError.otp}
                      touched={formik.touched.otp}
                    />

                  </div>
                )}
                <div className="col-span-6 sm:col-span-3">
                  <div className="relative">
                    <label
                      htmlFor="Password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <Input
                      type={"password"}
                      name={"password"}
                      placeholder={"Enter Your Password"}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.password}
                      serverError={validationError.password}
                      touched={formik.touched.password}
                    />
                    <button
                      type="button"
                      className="absolute text-gray-500 right-2 top-[40px] text-2xl"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                    </button>
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="PasswordConfirmation"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password Confirmation
                  </label>

                  <Input
                    type={"password"}
                    name={"confirm_password"}
                    placeholder={"Confirm Your Password"}
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.confirm_password}
                    serverError={validationError.confirm_password}
                    touched={formik.touched.confirm_password}
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="MarketingAccept" className="flex gap-4">
                    <input
                      type="checkbox"
                      id="MarketingAccept"
                      name="marketing_accept"
                      className="bg-white border-gray-200 rounded-md shadow-sm size-5"
                    />

                    <span className="text-sm text-gray-700">
                      I want to receive emails about events, product updates and
                      company announcements.
                    </span>
                  </label>
                </div>

                <div className="col-span-6">
                  <p className="text-sm text-gray-500">
                    By creating an account, you agree to our
                    <Link
                      to={TERMS_AND_CONDITION_ROUTE}
                      className="mx-1 text-primary-500"
                    >
                      terms and conditions
                    </Link>
                    and
                    <Link
                      to={PRIVACY_POLICY_ROUTE}
                      className="mx-1 text-primary-500"
                    >
                      privacy policy
                    </Link>
                    .
                  </p>
                </div>

                <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                  <button
                    type="submit"
                    disabled={loading}
                    className="inline-block px-12 py-3 text-sm font-medium text-white transition border rounded-md shrink-0 border-accent-500 bg-accent-500 hover:bg-transparent hover:text-accent-500 focus:outline-none focus:ring active:text-accent-500"
                  >
                    Create an account
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
      </section>
    </div>
  );
};

export default Register;
