import axios from "axios";
import authManager from "./authManager";
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "api/",
  headers: {
    "content-Type": "application/json",
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});
const ApiInterceptor = () => {
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        if (error.config) {
          const { url } = error.config;
          if (
            url.indexOf("getCartProducts") === -1 &&
            url.indexOf("addToCart") === -1
          ) {
            window.location.href = "/";
          }
        }
      }
      return Promise.reject(error);
    }
  );
  return null;
};
ApiInterceptor();
export default {
  getHomeData: (data) =>
    instance({
      method: "GET",
      url: `getHomeData?${data}`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  getHomeBanner: () =>
    instance({
      method: "GET",
      url: "getBanner",
    }),
  getParentCategory: () =>
    instance({
      method: "GET",
      url: `getParentCategory`,
    }),
  getCategoryWiseAttribute: (category) =>
    instance({
      method: "GET",
      url: `getCategoryWiseAttribute?categoryId=${category}`,
    }),
  flterProduct: (filter) =>
    instance({
      method: "GET",
      url: `flterProduct?filter=${filter}`,
    }),

  getUserSavedAddress: () =>
    instance({
      method: "GET",
      url: `auth/getUserSavedAddress`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  getProductReview: (data) =>
    instance({
      method: "GET",
      url: `review?${data}`,
    }),
  saveProductReview: (data) =>
    instance({
      method: "POST",
      url: `auth/review`,
      data,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  logout: () =>
    instance({
      method: "GET",
      url: `auth/logout`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  getProduct: (data) =>
    instance({
      method: "GET",
      url: `getProduct?${data}`,
    }),
  fetchProductDetail: (productId) =>
    instance({
      method: "GET",
      url: `getVariationDetail/${productId}`,
    }),
  registerUser: (data) =>
    instance({
      method: "POST",
      url: `register`,
      data,
    }),
  loginUser: (data) =>
    instance({
      method: "POST",
      url: `login`,
      data,
    }),
  sendOTP: (data) =>
    instance({
      method: "POST",
      url: `sendOTP`,
      data,
    }),
  addToCart: (data) =>
    instance({
      method: "POST",
      url: `auth/addToCart`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  addToWishlist: (data) =>
    instance({
      method: "POST",
      url: `auth/addToWishlist`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  createUserOrder: (data) =>
    instance({
      method: "POST",
      url: `auth/createUserOrder`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  updateUserOrder: (data) =>
    instance({
      method: "POST",
      url: `auth/updateUserOrder`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  getUserOrder: () =>
    instance({
      method: "GET",
      url: `auth/getUserOrder`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  getCartProduct: () =>
    instance({
      method: "GET",
      url: `auth/getCartProducts`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  cartCount: (data) =>
    instance({
      method: "POST",
      url: `auth/cartCount`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  deleteUserSavedAddress: (id) =>
    instance({
      method: "DELETE",
      url: `auth/deleteUserSavedAddress/${id}`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  sendDeleteOtp: () =>
    instance({
      method: "POST",
      url: `auth/sendDeleteOtp`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  deleteAccount: (data) =>
    instance({
      method: "DELETE",
      url: `auth/deleteAccount`,
      data,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  deleteDeleteOtp: () =>
    instance({
      method: "DELETE",
      url: `auth/deleteDeleteOtp`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  getWishlistProduct: () =>
    instance({
      method: "GET",
      url: `auth/getWishlistProduct`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  deleteFromCart: (data) =>
    instance({
      method: "DELETE",
      url: `auth/deleteFromCart`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  deleteFromWishlist: (data) =>
    instance({
      method: "DELETE",
      url: `auth/deleteFromWishlist`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  createPaymentOrder: (data) =>
    instance({
      method: "POST",
      url: `auth/createPaymentOrder`,
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  checkpincode: (pincode) =>
    instance({
      method: "POST",
      url: `auth/checkpincode/${pincode || "00"}`,
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  checkPaymentStatus: (data) =>
    instance({
      method: "POST",
      url: `auth/checkOrderStatus`,
      data,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
  addUserSavedAddress: (data) =>
    instance({
      method: "POST",
      url: `auth/addUserSavedAddress`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  sendOtp: (data) =>
    instance({
      method: "POST",
      url: `sendForgetOTP`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  changePassword: (data) =>
    instance({
      method: "POST",
      url: `changePassword`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
      data,
    }),
  cancelOrder: (orderId) =>
    instance({
      method: "POST",
      url: `auth/cancelOrder/${orderId}`,
      headers: {
        Authorization: `Bearer ${authManager.getAccessToken()}`,
      },
    }),
};
